<template>
  <SubNav
    class="subnav-position"
    :selected="'tenants'"
    :buildingId="buildingId"
  />
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link
          :to="{ name: 'BuildingTenants', params: { buildingId: buildingId } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Create a New Tenant</h6>
      <div class="form-outline mb-4">
        <label>Company Name</label>
        <input
          type="text"
          required
          v-model="companyName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Contact Name</label>
        <input
          type="text"
          required
          v-model="contactName"
          class="form-control"
        />
      </div>
      <div class="error">{{ errorForm }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!errorForm" class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Create</button>
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'BuildingTenants',
              params: { buildingId: buildingId }
            }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import { ref } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser.js";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const companyName = ref(null);
    const contactName = ref(null);
    const errorForm = ref(null);
    const isPending = ref(null);
    const { user } = getUser();
    const router = useRouter();
    const { error: errorAdd, addDoc } = useCollection("tenants");
    const handleSubmit = async () => {
      try {
        errorForm.value = null;
        isPending.value = true;
        const ref = await addDoc({
          companyName: companyName.value,
          contactName: contactName.value,
          managerId: user.value.uid,
          buildingId: props.buildingId,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (errorAdd.value) {
          errorForm.value = errorAdd.value;
        } else {
          router.push({
            name: "EditTenant",
            params: { buildingId: props.buildingId, tenantId: ref.id }
          });
        }
      } catch (err) {
        errorForm.value = err.message;
      }
    };
    return { companyName, contactName, errorForm, isPending, handleSubmit };
  }
};
</script>

<style>
</style>